import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../static/forms/team.json"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import TeamBlogs from "../components/TeamBlogs/TeamBlogs"
import TeamDetailsBanner from "../components/TeamDetailsBanner/TeamDetailsBanner"
import TeamProperty from "../components/TeamProperty/TeamProperty"
import DefaultForm from "../components/forms/default-form-module"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import TeamReview from "../components/TeamReview/TeamReview"
import logoBlack from "../images/logo-white.svg"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
import { teamURL } from "../lib/urls"
const { GetUrlSet } = require("@starberry/gatsby-theme-utils/Common/GGFXClient/Client") 

const TeamDetails = ({ data,pageContext }) => {
  const [pageData, setPageData]=useState(data?.strapiTeam)
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });

  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string

  const teamInfo = pageData
  const teamMemberReview= data?.allStrapiAdminDynamicReview?.edges;

  const [shareIcons, setShareIcons] = useState(false)

  const [show, setShow] = useState(false)

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const PageData =
    data?.allStrapiPage?.edges?.length > 0
      ? data?.allStrapiPage?.edges[0]?.node
      : ""

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  const { isLargeScreen, isMobile } = useDeviceMedia()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const message =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]?.node?.whatsapp_message

  
    const teamReviewData= data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]?.node

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  const revieweeData={
    revieweeName:teamInfo.name,
    revieweeEmail:teamInfo.email
  }

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/teams/'+urlParams.get('strapi_id')+'?populate[0]=tile_image&populate[1]=image&populate[2]=team_member&populate[3]=choose_areas'

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const previewData = await response.json();
        setLoading(false)
        setEnablePreview(false)
        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.tile_image = {}
          myPrevData.image = {}
          myPrevData.team_member = []
          myPrevData.team_member[0] = {}
          myPrevData.choose_areas = []
          myPrevData.choose_areas[0] = {}
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          setIsPreviewEnabled(true)
          myPrevData.image.url = previewData?.data?.attributes?.image?.data?.attributes?.url
          myPrevData.tile_image.url = previewData?.data?.attributes?.tile_image?.data?.attributes?.url
          myPrevData.content.data.content = previewData?.data?.attributes?.content
          myPrevData.imagetransforms = previewData?.data?.attributes?.imagetransforms
          myPrevData.name = previewData?.data?.attributes?.name
          myPrevData.slug = previewData?.data?.attributes?.slug
          myPrevData.email = previewData?.data?.attributes?.email
          myPrevData.designation = previewData?.data?.attributes?.designation
          myPrevData.phone = previewData?.data?.attributes?.phone
          myPrevData.whatsapp = previewData?.data?.attributes?.whatsapp
          myPrevData.negotiator_id = previewData?.data?.attributes?.negotiator_id
          myPrevData.language_speaks = previewData?.data?.attributes?.language_speaks
          myPrevData.Languages_Spoken = previewData?.data?.attributes?.Languages_Spoken

          setPageData(pageData => ({ ...pageData, myPrevData }));
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();

    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
    }

  },[])

    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }

    if(enablePreview){
      return null
    }
    
  return (
    <>
      <Layout popUpData={popUpData} nonpropertyDetails dark={true}>
        <div className="layout-padding-top"></div>
        <Container>
          <div className="breadcum-share-wrapper">
            <BreadcrumbModule
              type="share-button"
              mainparentname={`About`}
              mainparent={PageLinks?.about}
              parentname={`Meet the team`}
              parent={PageLinks?.team}
              pagename={teamInfo?.name}
            />

            <div
              className="share-news-details"
              onClick={() => openShareicons()}
            >
              <i className="icon grey-share" />
              <span>Share</span>
              {shareIcons && (
                <SocialShareComponent
                  openShareicons={openShareicons}
                  shareurl={pageurl}
                />
              )}
            </div>
          </div>
        </Container>

        <TeamDetailsBanner isPreviewEnabled={isPreviewEnabled} teamInfo={teamInfo} />
        <TeamProperty teamInfo={teamInfo} />
        <TeamBlogs teamInfo={teamInfo} />
        {teamMemberReview?.length>0&&
          <TeamReview 
          revieweeData={revieweeData}
          reviewData={teamMemberReview} 
          staticData={teamReviewData}/>
        }
      </Layout>

      {isLargeScreen && (
        <div className="team-cta-holder-mobile">
          <div className="cta-holder team-mobile">
            {teamInfo?.whatsapp && (
              <a
                target="_blank"
                className="whatsapp-link"
                href={`https://wa.me/${teamInfo?.whatsapp?.replace(
                  /[^A-Z0-9]/gi,
                  ""
                )}?text=${encodeURIComponent(message)}`}
              >
                <button className="button button-filled-green">
                  {" "}
                  <i className="icon black-whatsapp" />
                  {!isMobile && <span>whatsapp</span>}
                </button>
              </a>
            )}

            {teamInfo?.email && (
              <a
                onClick={handleModal}
                className="email-link"
                href="javascript:void(0)"
              >
                <button className="button button-outline-white">
                  {" "}
                  <i className="icon black-mail" /> <span>Email</span>
                </button>
              </a>
            )}
            {
              <Modal
                show={show}
                // onHide={handleClose}
                backdrop="static"
                centered
                dialogClassName="modal-popup-form team-form"
              >
                <Modal.Body>
                  <div className="popup-form-wrapper team-form">
                    <div className="close-modal" onClick={() => handleClose()}>
                      <i className="icon black-cancel-icon"></i>
                    </div>
                    <div>
                      <DefaultForm
                        fields={FormFields}
                        formTitle={"Contact" + " " + teamInfo?.name}
                        sourceUrl={pageurl}
                        emailSubUser={`Contact ${teamInfo?.name}`}
                        emailSubAdmin={`Contact ${teamInfo?.name}`}
                        team_name={teamInfo?.name}
                        to_email_id={teamInfo?.email}
                      />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {teamInfo?.phone && (
              <a className="phone-num" href={`tel:${teamInfo?.phone}`}>
                <button className="button button-outline-white">
                  {" "}
                  <i className="icon black-mobile" /> <span>Call</span>
                </button>
              </a>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export const Head = ({ data }) => {

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaTitle, setPreMetaTitle] = useState("")


  const teamInfo = data.strapiTeam

  let myimgtransforms = ""
  if (teamInfo?.imagetransforms?.tile_image_Transforms) {
    myimgtransforms = JSON.parse(
      teamInfo?.imagetransforms?.tile_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (teamInfo?.imagetransforms?.metaog_Transforms) {
    myimgtransforms1 = JSON.parse(teamInfo?.imagetransforms?.metaog_Transforms)
  }

  let getImg=teamInfo?.tile_image?.url?teamInfo?.tile_image?.url:""
  const findImage = getImg?.substring(getImg?.indexOf("i"));
  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  

  let imageUrl=""

  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }

  else if(myimgtransforms){
    // ogRender()
    const findImage = teamInfo?.tile_image?.url?.substring(teamInfo?.tile_image?.url?.indexOf("i.prod"));
    const ProcessedResultWebp=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    const ProcessedResultExt=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage][ext]:""
    const ProcessedResult=ProcessedResultExt?ProcessedResultExt["600ct520"]:ProcessedResultWebp?ProcessedResultWebp["600ct520"]:teamInfo?.tile_image?.url
    imageUrl=ProcessedResult
  }


  //new ggfx image
  const getImage =
  teamInfo?.tile_image?.url
      ? teamInfo?.tile_image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""

  const filterImage =
    getImage && teamInfo?.ggfx_results?.length > 0
      ? teamInfo?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""
  
  // filter image with image size
  const getOGImg =[]
    filterImage?.length > 0 && filterImage?.map(item=>{
      if(item?.transforms?.length>0){
         item.transforms.filter((trans) => {
            if(trans?.transform === "600ct350"&&trans?.format===ext){
              getOGImg.push(trans)
            }
         })
        
      }
    } )

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600ct350",
      id: teamInfo.strapi_id,
      field: "metaog",
      contentType: "team"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0){
        ogRenderNew()
    }
  },[]) 

  //----

 //----
 //breadscrumb schema
 let dynamicmenu1 ="Home"
 let dynamicmenu1Url = "https://www.hausandhaus.com/"
 let dynamicmenu2 = "About"
 let dynamicmenu2Url="https://www.hausandhaus.com/about-us/"
 let dynamicmenu3 = "Meet the Team"
 let dynamicmenu3Url = "https://www.hausandhaus.com/about-us/our-team/"
 let dynamicmenu4 = teamInfo?.name
 let dynamicmenu4Url = `${process.env.GATSBY_SITE_URL}${teamURL}${teamInfo?.slug}/`
 

 let breadcrumbJson = {
   "@context": "https://schema.org",	
 "@type": "BreadcrumbList",	
 itemListElement: [	
   {	
     "@type": "ListItem",	
     position: 1,	
     name: dynamicmenu1,	
     item: dynamicmenu1Url	
   },	
 ]	
 }

 if(dynamicmenu2){
   breadcrumbJson.itemListElement.push(
     {
       "@type": "ListItem",	
       position: 2,	
       name: dynamicmenu2,	
       item: dynamicmenu2Url	
     }
   )
 }

 if(dynamicmenu3){
   breadcrumbJson.itemListElement.push(
     {
       "@type": "ListItem",	
       position: 3,	
       name: dynamicmenu3,	
       item: dynamicmenu3Url	
     }
   )
 } 

 if(dynamicmenu4 && dynamicmenu4Url){
   breadcrumbJson.itemListElement.push(
     {
       "@type": "ListItem",	
       position: 4,	
       name: dynamicmenu4,	
       item: dynamicmenu4Url	
     }
   )
 } 


  const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl

  const metaTitle = teamInfo?.name + " | " + teamInfo?.designation + " | "
  const metaDesc = `Get to know about ${teamInfo?.name} here. Contact one of our real estate agents for assistance in finding the right Properties for you.`
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
            setPreMetaTitle(metaTitle)
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
      document.title =  `${metaTitle} haus & haus`;
    }
  }, [metaTitle]);
  
  
  return (
    <>
      <SEO title={metaTitle}
        description={metaDesc} imageUrl={pageImage}>
      </SEO>
      <script
        type="application/ld+json"
        >
          {JSON.stringify(breadcrumbJson, null, 2)}
        </script>
    </>
  ) 
}
export default TeamDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiTeam(strapi_id: { eq: $page_id }) {
      content {
        data {
          content
        }
      }
      designation
      email
      image {
        url
      }
      Languages_Spoken {
        strapi_json_value
      }
      tile_image {
        url
      }
      ggfx_results {
        src_cftle
        transforms {
          url
          transform
          format
        }
      }
      imagetransforms {
        image_Transforms
        tile_image_Transforms
        metaog_Transforms
      }
      name
      phone
      slug
      whatsapp
      strapi_id
      negotiator_id
    }
    allStrapiAdminDynamicReview {
      edges {
        node {
          broker
          client_name
          review
          review_date
          star_rating
          department
          email
          strapi_id
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          whatsapp_message
          team_review_heading
          team_review_desc {
            data {
              team_review_desc
            }
          }
        }
      }
    }

    allStrapiPage(filter: { layout: { eq: "people_landing_page" } }) {
      edges {
        node {
          add_page_modules {
            ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
              __typename
              ...GlobalPopUpFragment
            }
          }
        }
      }
    }
  }
`
