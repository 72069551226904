import { Link } from "gatsby"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { numberFormat } from "../Common/utils"
import "./PropertySliderCard.scss"
import GGFXImage from "../../modules/GGFXImage"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const PropertySliderCard = ({
  image,
  price,
  display_address,
  slug,
  department,
  crm_id,
  index,
  floorarea_min,
  floorarea_type,
  title,
  strapi_id,
  search_type,
  status,
  bathroom,
  imagetransforms,
  bedroom,
  slider_name,
  imageCount,
  completion_status,
  imageList,
  ggfx_results,
  introtext,
  negotiatorData,
}) => {
  const id = crm_id
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  let url = ""
  if (search_type === "sales" && department === "residential") {
    url = "/properties-for-sale/"
  } else if (search_type === "lettings" && department === "residential") {
    url = "/properties-to-rent/"
  } else if (search_type === "sales" && department === "new_developments") {
    url = "/new-homes/for-sale/"
  } else if (department === "commercial" && search_type === "sales") {
    url = "/commercial-properties-for-sale/"
  }
  else if (department === "commercial" && search_type === "lettings") {
    url = "/commercial-properties-to-rent/"
  }
  let floorarea

  if (floorarea_type === "squareFeet" && floorarea_min !== 0 && floorarea_min) {
    floorarea = Math.floor(floorarea_min / 10.764)
  }

  let processedImages
  if (slider_name === "team-property-slider") {
    processedImages = imagetransforms?.images_Transforms || JSON.stringify({})
  } else {
    processedImages =
      JSON.parse(imagetransforms)?.images_Transforms || JSON.stringify({})
  }

  var propertyLightImages =
    imageList?.length > 0 &&
    imageList.map(img => (img.srcUrl ? img.srcUrl : img.url))

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }

  const imagename = "property.images.tileimg"


  const flagValue = completion_status==="Offplan"?"Off Plan | Resale":completion_status === "Offplan Primary"?"Off Plan":
  status==="Off Market"?"Off Market Listing": (status==="Let"||status==="Sold") ? status:""
  var text = `Hi, I've come across the haus & haus Group and would like further information from you`

  return (
    <ScrollAnimation
      animateIn="animate__slideInUp"
      animateOnce
      delay={index * 100}
      offset={100}
    >
      <div className="propertyslider-card-section">
        <div className="image-section img-zoom">
          <Link to={`${url}${slug}/${strapi_id}/`}>
            <GGFXImage
              ImageSrc={image}
              altText={`${title}`}
              imagetransforms={ggfx_results?ggfx_results:""}
              renderer="srcSet"
              imagename={imagename}
              strapiID={strapi_id}
              classNames="property-card_image"
            />
              {flagValue &&
                <div className="status-flag">
                  <p>{flagValue}</p>
                </div>
                }
            {/* <div className="img_count-section">
              <a
                href="javascript:void(0)"
                onClick={e => openPropertyImage(e, 0)}
              >
                <i className="icon gallery-black-icon"></i>{" "}
                <span>{imageCount}</span>
              </a>
            </div> */}
          </Link>
        </div>

        <div className="content-section">
          <div className="content">
            <p className="price">{`AED ${numberFormat(price)}`} </p>
            <Link className="title" to={`${url}${slug}/${strapi_id}/`}>
              <span><i className="icon property-location-icon"/></span>
              <span className="title-text">{display_address}</span> 
            </Link>
            <p className="intro-text-feature">{introtext}</p>
          
            <div className="rooms-count">
              {bedroom !== 0 && (
                <div className="bedrooms">
                  <i className="icon icon-bed" /> <span>{bedroom}</span>
                </div>
              )}
              {bedroom !== 0 && 
              <span className="vetical-line-property"></span>
              }
              {bathroom !== 0 && (
                <div className="bathrooms">
                  <i className="icon icon-bath" /> <span>{bathroom}</span>
                </div>
              )}
              {bathroom !==0 &&
                <span className="vetical-line-property"></span>
              }
              {floorarea_min && floorarea_type === "squareFeet" && (
                <div className="area">
                  <i className="icon icon-sqft" />{" "}
                  <span>{numberFormat(floorarea_min)} sq.ft</span>
                </div>
              )}
            </div>
            </div>
            {(negotiatorData?.phone || negotiatorData?.whatsapp)&&
            <>
            <div className="horizontal_line-prop"></div>
            <div className="person-contact_info">
                  {negotiatorData?.phone && (
                    <Link
                      to={`tel:${negotiatorData?.phone?.replace(
                        / /g,
                        ""
                      )}`}
                      className="contact button"
                    >
                      <i className="icon icon-contact-call" />
                      <span>Call</span>
                    </Link>
                  )}
                  {negotiatorData?.whatsapp && (
                    <Link
                      to={`https://wa.me/${negotiatorData?.whatsapp?.replace(
                        / /g,
                        ""
                      )}?&text=${encodeURIComponent(text)}`}
                      className="contact button"
                      target="_blank"
                    >
                      <i className="icon icon-contact-whatsapp" />
                      <span>Whatsapp</span>
                    </Link>
                  )}
                  
            </div> 
            </>
            }
          </div>  
        

        {isOpen && (
          <Lightbox
            mainSrc={propertyLightImages[photoIndex]}
            nextSrc={
              propertyLightImages[(photoIndex + 1) % propertyLightImages.length]
            }
            prevSrc={
              propertyLightImages[
                (photoIndex + propertyLightImages.length - 1) %
                  propertyLightImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + propertyLightImages.length - 1) %
                  propertyLightImages.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
            }
          />
        )}
      </div>
    </ScrollAnimation>
  )
}

export default PropertySliderCard
